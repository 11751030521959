// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updateButton--Gnsw9{align-items:center;background-color:#fff;border:2px solid #5e1a1a;border-radius:10px;box-shadow:0 50px 100px -20px rgba(50,50,93,.25),0 30px 60px -30px rgba(0,0,0,.3);box-sizing:border-box;color:#5e1a1a;cursor:pointer;display:flex;font-family:Exo;font-size:16px;font-weight:700;justify-content:center;line-height:1;padding:8px 26px}@media (hover:hover){.updateButton--Gnsw9:hover{background-color:#5e1a1a;color:#fff}}@media (max-width:1024px){.updateButton--Gnsw9{font-size:13px;padding:6px 12px}}`, "",{"version":3,"sources":["webpack://./src/admin/components/updateButton/UpdateButton.module.css"],"names":[],"mappings":"AAAA,qBAaE,kBAAmB,CAHnB,qBAAyB,CAFzB,wBAAyB,CACzB,kBAAmB,CAMnB,iFAA2F,CAJ3F,qBAAsB,CAJtB,aAAc,CAKd,cAAe,CAXf,YAAa,CAEb,eAAkB,CAClB,cAAe,CACf,eAAiB,CASjB,sBAAuB,CARvB,aAAc,CAJd,gBAcF,CAEA,qBACE,2BAEE,wBAAyB,CADzB,UAEF,CACF,CAEA,0BACE,qBAEE,cAAe,CADf,gBAEF,CACF","sourcesContent":[".updateButton {\n  display: flex;\n  padding: 8px 26px;\n  font-family: 'Exo';\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 1;\n  color: #5e1a1a;\n  border: 2px solid #5e1a1a;\n  border-radius: 10px;\n  background-color: #ffffff;\n  box-sizing: border-box;\n  cursor: pointer;\n  align-items: center;\n  justify-content: center;\n  box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 60px -30px;\n}\n\n@media (hover: hover){ \n  .updateButton:hover {\n    color: #ffffff;\n    background-color: #5e1a1a;\n  }\n}\n\n@media (max-width: 1024px) {\n  .updateButton {\n    padding: 6px 12px;\n    font-size: 13px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"updateButton": `updateButton--Gnsw9`
};
export default ___CSS_LOADER_EXPORT___;
