// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appContent--OZrps{bottom:0;box-sizing:border-box;left:300px;overflow-y:scroll;padding:0 20px 30px;position:absolute;right:0;top:102px}@media (max-width:1024px){.appContent--OZrps{left:0;top:98px}}.appContent--OZrps::-webkit-scrollbar{width:2px}.appContent--OZrps::-webkit-scrollbar-track{background-color:transparent;border-radius:180px}.appContent--OZrps::-webkit-scrollbar-thumb{background-color:var(--scrollbar-color);border-radius:180px}`, "",{"version":3,"sources":["webpack://./src/components/appContent/AppContent.module.css"],"names":[],"mappings":"AAAA,mBAIE,QAAS,CAIT,qBAAsB,CAHtB,UAAW,CAEX,iBAAkB,CADlB,mBAAyB,CALzB,iBAAkB,CAElB,OAAQ,CADR,SAOF,CACA,0BACE,mBAEE,MAAO,CADP,QAEA,CACJ,CAGA,sCACE,SACF,CAEA,4CAEE,4BAA6B,CAD7B,mBAEF,CAEA,4CAEE,uCAAwC,CADxC,mBAEF","sourcesContent":[".appContent {\n  position: absolute;\n  top: 102px;\n  right: 0;\n  bottom: 0;\n  left: 300px;\n  padding: 0 20px 30px 20px;\n  overflow-y: scroll;\n  box-sizing: border-box;\n}\n@media (max-width: 1024px) {\n  .appContent {\n    top: 98px;\n    left: 0;\n    }\n}\n\n/* Scroll bar customiztion */\n.appContent::-webkit-scrollbar {\n  width: 2px;\n}\n\n.appContent::-webkit-scrollbar-track {\n  border-radius: 180px;\n  background-color: transparent;\n}\n\n.appContent::-webkit-scrollbar-thumb {\n  border-radius: 180px;\n  background-color: var(--scrollbar-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContent": `appContent--OZrps`
};
export default ___CSS_LOADER_EXPORT___;
