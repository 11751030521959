// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBlock--E0gTZ{position:relative;width:300px}.searchInput--Cbx5R{box-sizing:border-box;padding:10px}.listSection--UOLQl,.searchInput--Cbx5R{border:1px solid #ddd;border-radius:4px;width:100%}.listSection--UOLQl{background-color:#fff;list-style-type:none;margin:0;padding:0;position:absolute;z-index:1}.listItem--aayMI{border-bottom:1px solid #ddd;cursor:pointer;padding:10px}@media (max-width:1024px){.searchBlock--E0gTZ{width:100%}.searchInput--Cbx5R{padding:6px}}`, "",{"version":3,"sources":["webpack://./src/components/searchBox/SearchBox.module.css"],"names":[],"mappings":"AAAA,oBACE,iBAAkB,CAClB,WACF,CACA,oBAGE,qBAAsB,CADtB,YAIF,CACA,wCAHE,qBAAyB,CACzB,iBAAkB,CAJlB,UAgBF,CAVA,oBAQE,qBAAuB,CACvB,oBAAqB,CALrB,QAAS,CACT,SAAU,CAHV,iBAAkB,CADlB,SASF,CACA,iBAGE,4BAAgC,CADhC,cAAe,CADf,YAGF,CAEA,0BACE,oBACE,UACF,CACA,oBACE,WACF,CACF","sourcesContent":[".searchBlock {\n  position: relative;\n  width: 300px;\n}\n.searchInput {\n  width: 100%;\n  padding: 10px;\n  box-sizing: border-box;\n  border: 1px solid #dddddd;\n  border-radius: 4px;\n}\n.listSection {\n  z-index: 1;\n  position: absolute;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  border: 1px solid #dddddd;\n  border-radius: 4px;\n  background-color: white;\n  list-style-type: none;\n}\n.listItem {\n  padding: 10px;\n  cursor: pointer;\n  border-bottom: 1px solid #dddddd;\n}\n\n@media (max-width: 1024px) {\n  .searchBlock {\n    width: 100%;\n  }\n  .searchInput {\n    padding: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBlock": `searchBlock--E0gTZ`,
	"searchInput": `searchInput--Cbx5R`,
	"listSection": `listSection--UOLQl`,
	"listItem": `listItem--aayMI`
};
export default ___CSS_LOADER_EXPORT___;
