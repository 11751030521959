// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner--cq9Cm{animation:spin--QTKh5 1s linear infinite;border:8px solid #f3f3f3;border-radius:50%;border-top-color:#3498db;height:50px;margin:100px auto;width:50px}@keyframes spin--QTKh5{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}`, "",{"version":3,"sources":["webpack://./src/components/spinner/Spinner.module.css"],"names":[],"mappings":"AAAA,gBAOE,wCAAkC,CAFlC,wBAA6B,CAC7B,iBAAkB,CADlB,wBAA6B,CAH7B,WAAY,CACZ,iBAAkB,CAFlB,UAOF,CAEA,uBACE,GAAK,sBAAyB,CAC9B,GAAO,uBAA2B,CACpC","sourcesContent":[".spinner {\n  width: 50px;\n  height: 50px;\n  margin: 100px auto;\n  border: 8px solid #f3f3f3; /* Light grey */\n  border-top: 8px solid #3498db; /* Blue */\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `spinner--cq9Cm`,
	"spin": `spin--QTKh5`
};
export default ___CSS_LOADER_EXPORT___;
