// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--Mil0o{align-items:center;border-radius:16px;box-sizing:border-box;display:flex;flex-direction:column;gap:20px;justify-content:center;padding:16px;width:100%}`, "",{"version":3,"sources":["webpack://./src/admin/components/baseTemplate/BaseTemplate.module.css"],"names":[],"mappings":"AAAA,kBAKE,kBAAmB,CADnB,kBAAmB,CAKnB,qBAAsB,CARtB,YAAa,CAMb,qBAAsB,CACtB,QAAS,CAFT,sBAAuB,CAHvB,YAAa,CADb,UAQF","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  padding: 16px;\n  border-radius: 16px;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 20px;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--Mil0o`
};
export default ___CSS_LOADER_EXPORT___;
