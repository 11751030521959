// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar--czeIK{align-items:center;background-color:var(--nav-bg);box-sizing:border-box;display:flex;flex-direction:row;height:46px;justify-content:space-between;left:0;padding:0 20px;position:fixed;right:0;top:0;width:100%}`, "",{"version":3,"sources":["webpack://./src/components/navBar/NavBar.module.css"],"names":[],"mappings":"AAAA,eAaE,kBAAmB,CAJnB,8BAA+B,CAC/B,qBAAsB,CARtB,YAAa,CASb,kBAAmB,CAJnB,WAAY,CAKZ,6BAA8B,CAP9B,MAAO,CAGP,cAAe,CAPf,cAAe,CAGf,OAAQ,CADR,KAAM,CAGN,UAQF","sourcesContent":[".navbar {\n  position: fixed;\n  display: flex;\n  top: 0;\n  right: 0;\n  left: 0;\n  width: 100%;\n  height: 46px;\n  padding: 0 20px;\n  background-color: var(--nav-bg);\n  box-sizing: border-box;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `navbar--czeIK`
};
export default ___CSS_LOADER_EXPORT___;
