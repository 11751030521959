export const NIFTY_500 = [
  'RVNL',
  'INDIGOPNTS',
  'RAJESHEXPO',
  'VTL',
  'TIINDIA',
  'INDIACEM',
  'CGCL',
  'PRSMJOHNSN',
  'NLCINDIA',
  'FIVESTAR',
  'IRFC',
  'BRIGADE',
  'SOLARINDS',
  'HONASA',
  'KPIL',
  'KANSAINER',
  'ASIANPAINT',
  'WELSPUNLIV',
  'MGL',
  'JKCEMENT',
  'SYNGENE',
  'MANAPPURAM',
  'RAYMOND',
  'KIMS',
  'RECLTD',
  'CESC',
  'NHPC',
  'GMDCLTD',
  'BERGEPAINT',
  'GSPL',
  'MANKIND',
  'IRCON',
  'RAILTEL',
  'MFSL',
  'WESTLIFE',
  'DOMS',
  'GODREJCP',
  'ICICIPRULI',
  'CAMS',
  'SBICARD',
  'SBILIFE',
  'PFC',
  'ZYDUSLIFE',
  'METROBRAND',
  'HINDPETRO',
  'FORTIS',
  'GLENMARK',
  'CHOLAHLDNG',
  'DIVISLAB',
  'TORNTPOWER',
  'KALYANKJIL',
  'VGUARD',
  'BRITANNIA',
  'PNBHOUSING',
  'JKLAKSHMI',
  'LICI',
  'HSCL',
  'APLLTD',
  'HOMEFIRST',
  'BATAINDIA',
  'PIDILITIND',
  'GRASIM',
  'COLPAL',
  'MARICO',
  'TATAELXSI',
  'NTPC',
  'HDFCLIFE',
  'ICICIGI',
  'MUTHOOTFIN',
  'JUBLFOOD',
  'BIKAJI',
  'POWERGRID',
  'RATNAMANI',
  'BALRAMCHIN',
  'VOLTAS',
  'CUMMINSIND',
  'LICHSGFIN',
  'SIEMENS',
  'CAPLIPOINT',
  '360ONE',
  'PAYTM',
  'WELCORP',
  'NIACL',
  'CHOLAFIN',
  'ECLERX',
  'APLAPOLLO',
  'IGL',
  'ABFRL',
  'ARE&M',
  'APOLLOHOSP',
  'ZENSARTECH',
  'SOBHA',
  'REDINGTON',
  'SUVENPHAR',
  'SUMICHEM',
  'GODREJPROP',
  'QUESS',
  'DEEPAKNTR',
  'IRB',
  'JBCHEPHARM',
  'SONACOMS',
  'NAM-INDIA',
  'SUNPHARMA',
  'MAHSEAMLES',
  'EQUITASBNK',
  'ADANIPORTS',
  'BHARTIARTL',
  'KNRCON',
  'INOXWIND',
  'TATACOMM',
  'CASTROLIND',
  'HUDCO',
  'LALPATHLAB',
  'FEDERALBNK',
  'IOB',
  'CANFINHOME',
  'PRESTIGE',
  'LUPIN',
  'HINDUNILVR',
  'TITAN',
  'TORNTPHARM',
  'HONAUT',
  'RAINBOW',
  'INDIAMART',
  'SUNTECK',
  'ATUL',
  'IPCALAB',
  'CAMPUS',
  'ASTRAL',
  'BPCL',
  'UBL',
  'CHEMPLASTS',
  'MSUMI',
  'MINDACORP',
  'SHREECEM',
  'IDBI',
  'TATACONSUM',
  'AUROPHARMA',
  'SAFARI',
  'MAPMYINDIA',
  'BIRLACORPN',
  'HAVELLS',
  'AJANTPHARM',
  'METROPOLIS',
  'NH',
  'SUNDRMFAST',
  'STARHEALTH',
  'IOC',
  'PGHH',
  'CONCORDBIO',
  'GMMPFAUDLR',
  'ADANIPOWER',
  'SRF',
  'AETHER',
  'DMART',
  'INDIANB',
  'OBEROIRLTY',
  'SUNDARMFIN',
  'CONCOR',
  'TVSMOTOR',
  'ONGC',
  'MPHASIS',
  'OIL',
  'EICHERMOT',
  'ASTERDM',
  'AXISBANK',
  'NYKAA',
  'KPRMILL',
  'AAVAS',
  'BAJAJFINSV',
  'JSWENERGY',
  'DRREDDY',
  'LTIM',
  'CIPLA',
  'MTARTECH',
  'ERIS',
  'GUJGASLTD',
  'RBA',
  'PAGEIND',
  'GILLETTE',
  'INDHOTEL',
  'CDSL',
  'RADICO',
  'POLYMED',
  'DABUR',
  'PATANJALI',
  'COROMANDEL',
  'ADANIENSOL',
  'GAIL',
  'SANOFI',
  'DALBHARAT',
  'ANURAS',
  'TATATECH',
  'ATGL',
  'BAJAJ-AUTO',
  'BLS',
  'FINEORG',
  'ANANDRATHI',
  'CROMPTON',
  'FINPIPE',
  'VAIBHAVGBL',
  'SYRMA',
  'AVANTIFEED',
  'AMBER',
  'KFINTECH',
  'TRITURBINE',
  'ADANIGREEN',
  'TECHM',
  'BEL',
  'TEJASNET',
  'TRIDENT',
  'JSWINFRA',
  'EPL',
  'JINDALSAW',
  'BAJFINANCE',
  'BLUEDART',
  'NSLNISP',
  'MANYAVAR',
  'TRIVENI',
  'TATAPOWER',
  'MARUTI',
  'CENTURYPLY',
  'PRINCEPIPE',
  'UCOBANK',
  'POLICYBZR',
  'LT',
  'GRANULES',
  'BHEL',
  'MEDANTA',
  'CYIENT',
  'HDFCBANK',
  'TMB',
  'BHARATFORG',
  'MRF',
  'SAPPHIRE',
  'ABCAPITAL',
  'KAJARIACER',
  'UNITDSPR',
  'ICICIBANK',
  'DLF',
  'ITC',
  'RELIANCE',
  'JIOFIN',
  'CENTRALBK',
  'PNCINFRA',
  'ABB',
  'ADANIENT',
  'RRKABEL',
  'PVRINOX',
  'ULTRACEMCO',
  'SBFC',
  'INDUSTOWER',
  'GODREJIND',
  'ISEC',
  'UJJIVANSFB',
  'PETRONET',
  'FDC',
  'PIIND',
  'CERA',
  'ACI',
  'IRCTC',
  'PHOENIXLTD',
  'COALINDIA',
  'INDUSINDBK',
  'RAMCOCEM',
  'GRAPHITE',
  'BALKRISIND',
  'NESTLEIND',
  'TANLA',
  'INDIGO',
  'CHALET',
  'BOSCHLTD',
  'HDFCAMC',
  'UPL',
  'NAVINFLUOR',
  'INFY',
  'WHIRLPOOL',
  'TATAINVEST',
  'AARTIIND',
  'LTTS',
  'KEI',
  'NUVAMA',
  'CCL',
  'KAYNES',
  'VBL',
  'ZOMATO',
  'VIPIND',
  'GMRINFRA',
  'KOTAKBANK',
  'HAL',
  'TRENT',
  'BALAMINES',
  'MRPL',
  'RENUKA',
  'DIXON',
  'TTML',
  'ZFCVINDIA',
  'ABBOTINDIA',
  'EIDPARRY',
  'ENGINERSIN',
  'SWANENERGY',
  'LLOYDSME',
  'ANGELONE',
  'UNIONBANK',
  'GPIL',
  'HAPPSTMNDS',
  'FLUOROCHEM',
  'ACC',
  'BANKINDIA',
  'BAJAJHLDNG',
  'EASEMYTRIP',
  'JUBLPHARMA',
  'MAXHEALTH',
  'MAHLIFE',
  'AWL',
  'MEDPLUS',
  'FINCABLES',
  'ASHOKLEY',
  'CUB',
  'JINDALSTEL',
  'BAYERCROP',
  'TATAMOTORS',
  'CRISIL',
  'CANBK',
  'IDEA',
  'SHRIRAMFIN',
  'KPITTECH',
  'CHENNPETRO',
  'IDFCFIRSTB',
  'SUZLON',
  'AFFLE',
  'RTNINDIA',
  'ALOKINDS',
  'NBCC',
  'OFSS',
  'SWSOLAR',
  'POLYCAB',
  'ENDURANCE',
  'ELGIEQUIP',
  'WIPRO',
  'JSWSTEEL',
  'TATAMTRDVR',
  'JUBLINGREA',
  'EMAMILTD',
  'NATCOPHARM',
  'DELHIVERY',
  'ITI',
  'GLS',
  'CSBBANK',
  'MASTEK',
  'SBIN',
  'RBLBANK',
  '3MINDIA',
  'UTIAMC',
  'M&MFIN',
  'J&KBANK',
  'RHIM',
  'HEROMOTOCO',
  'USHAMART',
  'NAUKRI',
  'NCC',
  'BORORENEW',
  'VIJAYA',
  'BIOCON',
  'COFORGE',
  'AUBANK',
  'MOTILALOFS',
  'APOLLOTYRE',
  'MOTHERSON',
  'GLAXO',
  'JKPAPER',
  'LAURUSLABS',
  'RKFORGE',
  'DEVYANI',
  'APTUS',
  'PEL',
  'SONATSOFTW',
  'HAPPYFORGE',
  'CARBORUNIV',
  'SUNTV',
  'PERSISTENT',
  'SUPREMEIND',
  'HCLTECH',
  'IDFC',
  'DATAPATTNS',
  'HEG',
  'TATACHEM',
  'SKFINDIA',
  'GICRE',
  'INTELLECT',
  'MAHABANK',
  'ALKEM',
  'GESHIP',
  'COCHINSHIP',
  'RITES',
  'BEML',
  'LTF',
  'BSOFT',
  'VARROC',
  'ALKYLAMINE',
  'ALLCARGO',
  'TCS',
  'PPLPHARMA',
  'ASAHIINDIA',
  'HINDZINC',
  'TVSSCS',
  'BANKBARODA',
  'PRAJIND',
  'APARINDS',
  'CLEAN',
  'DCMSHRIRAM',
  'EIHOTEL',
  'TIMKEN',
  'JWL',
  'AMBUJACEM',
  'SJVN',
  'GAEL',
  'CGPOWER',
  'CHAMBLFERT',
  'SPARC',
  'ROUTE',
  'LATENTVIEW',
  'MHRIL',
  'SIGNATURE',
  'PCBL',
  'CENTURYTEX',
  'EXIDEIND',
  'CRAFTSMAN',
  'JSL',
  'JUSTDIAL',
  'LXCHEM',
  'CEATLTD',
  'STLTECH',
  'TATASTEEL',
  'HINDALCO',
  'KARURVYSYA',
  'JMFINANCIL',
  'ELECON',
  'JYOTHYLAB',
  'IBULHSGFIN',
  'CELLO',
  'GLAND',
  'NUVOCO',
  'GODFRYPHLP',
  'VEDL',
  'TV18BRDCST',
  'KSB',
  'GNFC',
  'JBMA',
  'JAIBALAJI',
  'AIAENG',
  'NATIONALUM',
  'NMDC',
  'GSFC',
  'LODHA',
  'LEMONTREE',
  'MMTC',
  'PNB',
  'HBLPOWER',
  'GRSE',
  'CREDITACC',
  'BDL',
  'ESCORTS',
  'CIEINDIA',
  'DEEPAKFERT',
  'KRBL',
  'MAZDOCK',
  'SHYAMMETL',
  'ACE',
  'POONAWALLA',
  'ASTRAZEN',
  'POWERINDIA',
  'NETWORK18',
  'SAREGAMA',
  'OLECTRA',
  'SCHNEIDER',
  'AEGISLOG',
  'YESBANK',
  'TITAGARH',
  'ZEEL',
  'SCHAEFFLER',
  'BSE',
  'LINDEINDIA',
  'HFCL',
  'SAIL',
  'GPPL',
  'IIFL',
  'KEC',
  'FSL',
  'GRINDWELL',
  'HINDCOPPER',
  'THERMAX',
  'IEX',
  'MCX',
  'BANDHANBNK',
  'UNOMINDA',
  'RCF',
  'FACT',
  'BBTC',
  'M&M',
  'BLUESTARCO',
];
