// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appOverlay--DIKiT{background-color:gray;bottom:0;left:0;opacity:.5;position:fixed;right:0;top:0;z-index:9998}`, "",{"version":3,"sources":["webpack://./src/components/appOverlay/AppOverlay.module.css"],"names":[],"mappings":"AAAA,mBAOE,qBAAsB,CAFtB,QAAS,CACT,MAAO,CAEP,UAAY,CANZ,cAAe,CAEf,OAAQ,CADR,KAAM,CAFN,YAQF","sourcesContent":[".appOverlay {\n  z-index: 9998;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: gray;\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appOverlay": `appOverlay--DIKiT`
};
export default ___CSS_LOADER_EXPORT___;
