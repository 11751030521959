// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardContainer--akRGh{display:grid;grid-auto-rows:min-content;grid-template-columns:repeat(4,1fr);grid-template-rows:50px;justify-items:center;grid-gap:16px;gap:16px}@media (max-width:1024px){.dashboardContainer--akRGh{grid-template-columns:repeat(1,1fr);grid-template-rows:36px}}.searchInput--ORj7r{border:1px solid #ddd;border-radius:30px;box-sizing:border-box;font-size:20px;font-weight:600;grid-column:1/-1;padding:10px;width:400px}@media (max-width:1024px){.searchInput--ORj7r{font-size:16px;width:100%}}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA,2BACE,YAAa,CAIb,0BAA2B,CAF3B,mCAAqC,CACrC,uBAAwB,CAFxB,oBAAqB,CAIrB,aAAS,CAAT,QACF,CAEA,0BACE,2BACE,mCAAqC,CACrC,uBACF,CACF,CAEA,oBAME,qBAAyB,CACzB,kBAAmB,CAFnB,qBAAsB,CAFtB,cAAe,CACf,eAAgB,CAIhB,gBAAmB,CANnB,YAAa,CADb,WAQF,CAEA,0BACE,oBAEE,cAAe,CADf,UAEF,CACF","sourcesContent":[".dashboardContainer {\n  display: grid;\n  justify-items: center;\n  grid-template-columns: repeat(4, 1fr);\n  grid-template-rows: 50px;\n  grid-auto-rows: min-content;\n  gap: 16px;\n}\n\n@media (max-width: 1024px) {\n  .dashboardContainer {\n    grid-template-columns: repeat(1, 1fr);\n    grid-template-rows: 36px;\n  }\n}\n\n.searchInput {\n  width: 400px;\n  padding: 10px;\n  font-size: 20px;\n  font-weight: 600;\n  box-sizing: border-box;\n  border: 1px solid #dddddd;\n  border-radius: 30px;\n  grid-column: 1 / -1;\n}\n\n@media (max-width: 1024px) {\n  .searchInput {\n    width: 100%;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardContainer": `dashboardContainer--akRGh`,
	"searchInput": `searchInput--ORj7r`
};
export default ___CSS_LOADER_EXPORT___;
