// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./variables.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Exo.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Exo';
  font-weight: 500;
  src: local('Exo'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
}

/* Scroll bar customiztion */
*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  border-radius: 180px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 180px;
  background-color: var(--scrollbar-color);
}`, "",{"version":3,"sources":["webpack://./src/styles/main.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,0EAAyD;AAC3D;;AAEA,4BAA4B;AAC5B;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,oBAAoB;EACpB,wCAAwC;AAC1C","sourcesContent":["@import url('variables.css');\n\n@font-face {\n  font-family: 'Exo';\n  font-weight: 500;\n  src: local('Exo'), url(./fonts/Exo.woff2) format('woff2');\n}\n\n/* Scroll bar customiztion */\n*::-webkit-scrollbar {\n  width: 2px;\n  height: 2px;\n}\n\n*::-webkit-scrollbar-track {\n  border-radius: 180px;\n  background-color: transparent;\n}\n\n*::-webkit-scrollbar-thumb {\n  border-radius: 180px;\n  background-color: var(--scrollbar-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
