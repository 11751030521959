// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay--FiWAj{align-items:center;background-color:hsla(0,0%,50%,.5);bottom:0;display:flex;justify-content:center;left:0;position:fixed;right:0;top:0;z-index:9999}`, "",{"version":3,"sources":["webpack://./src/components/errorFallback/error.module.css"],"names":[],"mappings":"AAAA,gBASE,kBAAmB,CADnB,kCAAyC,CAFzC,QAAS,CAHT,YAAa,CAOb,sBAAuB,CAHvB,MAAO,CALP,cAAe,CAGf,OAAQ,CADR,KAAM,CAHN,YAUF","sourcesContent":[".overlay {\n  z-index: 9999;\n  position: fixed;\n  display: flex;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: rgba(128, 128, 128, .5);\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `overlay--FiWAj`
};
export default ___CSS_LOADER_EXPORT___;
