// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appGrid--tz98v{bottom:0;display:flex;flex-direction:row;left:0;position:fixed;right:0;top:0}`, "",{"version":3,"sources":["webpack://./src/components/appGrid/appGrid.module.css"],"names":[],"mappings":"AAAA,gBAKE,QAAS,CAHT,YAAa,CAKb,kBAAmB,CADnB,MAAO,CALP,cAAe,CAGf,OAAQ,CADR,KAKF","sourcesContent":[".appGrid {\n  position: fixed;\n  display: flex;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appGrid": `appGrid--tz98v`
};
export default ___CSS_LOADER_EXPORT___;
