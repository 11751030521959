// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--JB0h4{align-items:center;background-color:#e9f5db;border-radius:10px;box-shadow:0 5px 15px rgba(0,0,0,.35);display:flex;flex-direction:column;font-family:Exo;gap:20px;justify-content:center;line-height:1;padding:4px;text-align:center;width:100%}.container--JB0h4,.title--RL3Mj{box-sizing:border-box}.title--RL3Mj{background-color:#718355;border-radius:8px;color:#fff;font-size:20px;font-weight:700;padding:8px 16px}.status--yFWfr{display:flex;font-size:18px;font-weight:600;gap:20px}.statusContent--Ppal6{font-size:16px;font-weight:400}.ack--OPLDc{display:flex;font-size:18px;font-weight:600;gap:20px}`, "",{"version":3,"sources":["webpack://./src/admin/components/responseTemplate/ResponseTemplate.module.css"],"names":[],"mappings":"AAAA,kBASE,kBAAmB,CADnB,wBAAyB,CADzB,kBAAmB,CAOnB,qCAA0C,CAb1C,YAAa,CAUb,qBAAsB,CAPtB,eAAkB,CAQlB,QAAS,CAFT,sBAAuB,CALvB,aAAc,CAFd,WAAY,CAGZ,iBAAkB,CAJlB,UAaF,CACA,gCAHE,qBAWF,CARA,cAME,wBAAyB,CADzB,iBAAkB,CADlB,UAAc,CAFd,cAAe,CACf,eAAiB,CAFjB,gBAOF,CAEA,eACE,YAAa,CAEb,cAAe,CACf,eAAgB,CAFhB,QAGF,CAEA,sBACE,cAAe,CACf,eACF,CAEA,YACE,YAAa,CAEb,cAAe,CACf,eAAgB,CAFhB,QAGF","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  padding: 4px;\n  font-family: 'Exo';\n  line-height: 1;\n  text-align: center;\n  border-radius: 10px;\n  background-color: #E9F5DB;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 20px;\n  box-sizing: border-box;\n  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;\n}\n.title {\n  padding: 8px 16px;\n  font-size: 20px;\n  font-weight: bold;\n  color: #ffffff;\n  border-radius: 8px;\n  background-color: #718355;\n  box-sizing: border-box;\n}\n\n.status {\n  display: flex;\n  gap: 20px;\n  font-size: 18px;\n  font-weight: 600;\n}\n\n.statusContent {\n  font-size: 16px;\n  font-weight: 400;\n}\n\n.ack {\n  display: flex;\n  gap: 20px;\n  font-size: 18px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--JB0h4`,
	"title": `title--RL3Mj`,
	"status": `status--yFWfr`,
	"statusContent": `statusContent--Ppal6`,
	"ack": `ack--OPLDc`
};
export default ___CSS_LOADER_EXPORT___;
