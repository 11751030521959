// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --nav-bg: #F2ECFD;
    --drawer-bg: #F2ECFD;
    --drawer-item-active: #422D6E;
    --scrollbar-color: #422D6E;
    --table-bg: #f7f7f7;
    --table-body-bg: #ffffff;
    --table-border-color: #d0d5e2;
    --text-color: grey;
    --default-bg-color: teal;
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,6BAA6B;IAC7B,0BAA0B;IAC1B,mBAAmB;IACnB,wBAAwB;IACxB,6BAA6B;IAC7B,kBAAkB;IAClB,wBAAwB;AAC5B","sourcesContent":[":root {\n    --nav-bg: #F2ECFD;\n    --drawer-bg: #F2ECFD;\n    --drawer-item-active: #422D6E;\n    --scrollbar-color: #422D6E;\n    --table-bg: #f7f7f7;\n    --table-body-bg: #ffffff;\n    --table-border-color: #d0d5e2;\n    --text-color: grey;\n    --default-bg-color: teal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
